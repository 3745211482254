import { constants } from "../../utils/constants";

export const styles = {
  container: {
    "& .MuiDialog-paper": {
      backgroundColor: constants.colors.primary,
      color: constants.colors.white,
      borderRadius: "20px",
      minWidth: { xs: "none", md: "fit-content" },
    },
    "& .MuiDialogTitle-root": {
      fontFamily: constants.fonts.autography,
      color: constants.colors.white,
      fontSize: { xs: "35px", md: "40px" },
      mt: "1rem",
      lineHeight: "35px",
      alignSelf: "center",
    },
    "& .MuiDialogContentText-root": {
      fontFamily: constants.fonts.poppins,
      color: constants.colors.white,
      fontSize: { xs: "12px", md: "16px" },
      fontWeight: 600,
      lineHeight: { xs: "18px", md: "30px" },
      textAlign: "center",
      m: "0.5rem",
    },
  },
  form: {
    ml: 1,
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  inputs: {
    color: constants.colors.white,
    borderColor: constants.colors.white,
    width: { xs: "100%", md: "750px" },
    "& .MuiOutlinedInput-input": {
      fontFamily: constants.fonts.poppins,
      fontSize: { xs: "12px", md: "16px" },
      fontWeight: 600,

      "&:placeholder ": {
        color: constants.colors.white,
      },
      "&:invalid ": {
        color: constants.colors.white,
      },
      "&:-webkit-autofill-selected": {
        WebkitBoxShadow: `0 0 0 100px  ${constants.colors.primary}`,
        WebkitTextFillColor: "default",
      },
    },
    "& .MuiInputLabel-outlined": {
      color: constants.colors.white,
      fontFamily: constants.fonts.poppins,
      fontSize: { xs: "12px", md: "16px" },
      fontWeight: 600,
      "&:focus": { color: constants.colors.white },
      "&.Mui-focused": { color: constants.colors.white },
      "&:-webkit-autofill-selected": {
        WebkitBoxShadow: `0 0 0 100px  ${constants.colors.primary}`,
        WebkitTextFillColor: "default",
      },
    },
    "& .MuiInputLabel-outlined.Mui-disabled": {
      fontFamily: constants.fonts.poppins,
      fontWeight: 600,
      color: constants.colors.white,
    },
    "& .MuiOutlinedInput-root.Mui-disabled": {
      "& fieldset ": {
        borderColor: "gray",
      },
      "&:hover fieldset": {
        borderColor: "gray",
      },
    },
    "& .MuiOutlinedInput-root": {
      borderColor: constants.colors.white,
      "& fieldset": {
        borderColor: constants.colors.white,
      },
      "&:hover fieldset": {
        borderColor: constants.colors.white,
      },
      "&.Mui-focused fieldset": {
        borderColor: constants.colors.white,
      },
      "&:-webkit-autofill-selected": {
        WebkitBoxShadow: `0 0 0 100px  ${constants.colors.primary}`,
        WebkitTextFillColor: "default",
      },
    },
    "&:-webkit-autofill selected": {
      WebkitBoxShadow: `0 0 0 100px  ${constants.colors.primary}`,
      WebkitTextFillColor: "default",
    },
  },
  plusForm: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "-webkit-fill-available",
  },
  inputBox: { mr: { xs: "0rem", md: "0" } },
  addBtnBox: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    mx: "1.5rem",
  },
  iconBtn: {
    color: constants.colors.white,
    "&:hover": {
      backgroundColor: constants.colors.primary,
    },
  },
  addText: {
    fontFamily: constants.fonts.poppins,
    fontSize: { xs: "12px", md: "16px" },
    fontWeight: 600,
  },
  btn: {
    color: constants.colors.white,
    fontFamily: constants.fonts.poppins,
    fontSize: { xs: "12px", md: "14px" },
    fontWeight: 600,
    letterSpacing: "2px",
    m: "1rem",
    border: "1px solid white",
  },
  checkboxLabel: {
    "& .MuiFormControlLabel-label": {
      fontFamily: constants.fonts.poppins,
      fontSize: { xs: "12px", md: "16px" },
      fontWeight: 600,
      lineHeight: { xs: "18px", md: "30px" },
    },
  },
  checkbox: {
    color: constants.colors.white,
    "&.Mui-checked": {
      color: constants.colors.white,
    },
  },
  radioGroup: {
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
};
