import { useSearchParams } from "react-router-dom";
import { ConfirmationPage } from "../ConfirmationPage/ConfirmationPage";
import { Hotel } from "../Hotel/Hotel";
import { InfoGuest } from "../InfoGuest/InfoGuest";
import { Itinerary } from "../Itinerary/Itinerary";
import { Location } from "../Location/Location";
import { Presentation } from "../Presentation/Presentation";
import { WeddingGifts } from "../WeddingGifts/WeddingGifts";
import { useEffect, useState } from "react";
import { checkInvite } from "../../services/apis";
import { FAQs } from "../FAQ/FAQ";
import { NotRsvp } from "../NotRsvp";

const limitDate = new Date("05/01/2023");

export const Home = () => {
  const [guestData, setGuestData] = useState();
  const [rsvpIdExists, setRsvpIdExists] = useState(false);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const rsvpId = searchParams.get("rsvpId");
    if (rsvpId && rsvpId.length) {
      getInviteData(rsvpId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams]);

  const getInviteData = async (rsvpId) => {
    const response = await checkInvite(rsvpId);
    if (!response || !response.Item || !response.Item.isActive) return;
    setGuestData(response.Item);
    setRsvpIdExists(true);
  };

  return (
    <>
      <Presentation guestData={guestData} />
      {rsvpIdExists ? (
        <>
          <InfoGuest guestData={guestData} />
          <Itinerary />
          <Location />
          <ConfirmationPage guestData={guestData} />
          <Hotel />
          <FAQs />
          <WeddingGifts />
        </>
      ) : (
        <NotRsvp />
      )}
    </>
  );
};
