import { constants } from "../../utils/constants";

export const styles = {
  container: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    fontFamily: constants.fonts.poppins,
  },
  daysBox: { px: "1rem" },
  separations: {
    fontFamily: constants.fonts.poppins,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    fontWeight: 600,
  },
  numbers: {
    fontFamily: constants.fonts.poppins,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    fontWeight: 600,
    px: "0.5rem",
  },

  text: {
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    fontSize: { xs: "12px", md: "20px" },
    letterSpacing: "0.06em",
    lineHeight: { xs: "18px", md: "30px" },
  },
};
