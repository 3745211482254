import { Grid, Typography, Box, Button } from "@mui/material";
import { styles } from "./styles";

import image1 from "../../assets/templo.svg";
import image2 from "../../assets/terrace.svg";

const locations = [
  {
    subtitle: "CEREMONIA",
    image: image1,
    hr: "Hora: 3:00 pm",
    direction:
      "Templo María Reina del Palomar, Valle de Santana 283, Fracc. El Palomar. Tlajomulco de Zúñiga, Jalisco. ",
    maps: "https://goo.gl/maps/CGSRp6t4NY78J6jG8",
  },
  {
    subtitle: "RECEPCIÓN",
    image: image2,
    hr: "Hora: 5:30 pm",
    direction: "Terraza Gavilanes. Morelos, 181. Los Gavilanes, Jalisco.",
    maps: "https://goo.gl/maps/FPxy65zNf6nAdgy17",
  },
];

export const Location = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container}>
          <Box sx={styles.titleContainer}>
            <Typography sx={styles.title}>Dónde y cuándo</Typography>
          </Box>
          <Box sx={styles.locationsContainer}>
            {locations.map((location) => {
              const { subtitle, image, hr, direction, maps } = location;
              return (
                <Box key={subtitle} sx={styles.locationBox}>
                  <Typography sx={styles.subtitle}>{subtitle}</Typography>
                  <img alt="imagen-locaciones" src={image} />
                  <Box sx={styles.textBox}>
                    <Typography sx={styles.text}>{hr}</Typography>
                    <Typography sx={styles.text}>{direction}</Typography>
                  </Box>
                  <a
                    target="_blank"
                    rel="noreferrer"
                    style={{ textDecoration: "none" }}
                    href={maps}
                  >
                    <Button variant="outlined" sx={styles.btns}>
                      Ver mapa
                    </Button>
                  </a>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
