import { Box, Typography, Grid } from "@mui/material";
import { CountDown } from "../../components/CountDown/CountDown";
import { styles } from "./styles";
// import { Link as Scroll } from "react-scroll";

export const Presentation = ({ guestData }) => {
  const weddingDate = "2023-05-20";

  return (
    <Grid container columns={12}>
      <Grid item xs={12} md={8}>
        <Box sx={styles.presentation}>
          <Box sx={styles.bottomText}>
            <Typography sx={styles.names}>Andrea & Felipe</Typography>
            <Typography sx={styles.date}>20 de Mayo 2023</Typography>
          </Box>
          <Box sx={styles.bottomText}>
            <Typography sx={styles.names}>Nuestra Boda</Typography>
            <CountDown weddingDate={weddingDate} />
          </Box>
          {/* {guestData && (
            <Box>
              <Scroll to="rsvp" smooth={true}>
                <Button variant="outlined" sx={styles.confirmBtn}>
                  Recepción
                </Button>
              </Scroll>
            </Box>
          )} */}
        </Box>
      </Grid>
      <Grid item md={4}>
        <Box sx={styles.picture}></Box>
      </Grid>
    </Grid>
  );
};
