import { useEffect, useState } from "react";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { RsvpListRow } from "./RsvpListRow";

export const RsvpList = ({
  list: entireList,
  handleMetrics,
  sendWhatsAppMobile,
  sendWhatsAppWeb,
  deleteRsvp,
  rejectRsvp,
  handleEdit,
  sendInviteSummary,
}) => {
  const [metrics, setMetrics] = useState(null);
  const [list, setList] = useState(entireList);

  useEffect(() => {
    handleMetrics(metrics);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metrics]);

  useEffect(() => {
    const prevMetrics = {
      invited: 0,
      accepted: 0,
      inactive: 0,
      extra: 0,
      beverages: {},
      rejected: 0,
      children: 0,
      invites: list?.length,
    };
    list.forEach((invitee) => {
      prevMetrics.invited += 1 + (Number(invitee.extraInvites) ?? 0);
      if (!invitee.isActive) {
        prevMetrics.inactive += 1;
        return;
      }
      if (invitee.accepted === true) {
        prevMetrics.accepted += 1;
        prevMetrics.extra += invitee.invitees?.length ?? 0;
        invitee.beverages?.forEach((b) => {
          if (!prevMetrics.beverages[b]) {
            prevMetrics.beverages[b] = 1;
          } else {
            prevMetrics.beverages[b] += 1;
          }
        });
      }
      if (invitee.rejected === true) {
        prevMetrics.rejected += 1;
      }
      if (invitee.hasChildren) {
        prevMetrics.children += Number(invitee.childrenQty ?? 0);
      }
    });
    setMetrics(prevMetrics);
  }, [list]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            <TableCell>Veces abierto</TableCell>
            <TableCell>Nombre</TableCell>
            <TableCell>invitaciones Extra</TableCell>
            <TableCell>Invitados</TableCell>
            <TableCell>Telefono</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Necesita Hotel?</TableCell>
            <TableCell>Bebidas</TableCell>
            <TableCell>Tiene Hijos?</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {list.map((row) => (
            <RsvpListRow
              key={row.rsvpId}
              sendWhatsAppMobile={sendWhatsAppMobile}
              sendWhatsAppWeb={sendWhatsAppWeb}
              deleteRsvp={deleteRsvp}
              rejectRsvp={rejectRsvp}
              row={row}
              handleEdit={handleEdit}
              sendInviteSummary={sendInviteSummary}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
