import { Route, Routes, HashRouter } from "react-router-dom";
import { Home } from "./pages/Home";
import { Admin } from "./pages/Admin";

function App() {
  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />,
        <Route path="/admin" element={<Admin />} />,
      </Routes>
    </HashRouter>
  );
}

export default App;
