import img from "../../assets/flowersbcg.jpg";
import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: { xs: "auto", md: "100vh" },
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    mt: "4rem",
    fontFamily: constants.fonts.autography,
    fontSize: { xs: "45px", md: "120px" },
    lineHeight: { xs: "85px", md: "226px" },
    color: constants.colors.primary,
  },
  guestName: {
    fontFamily: constants.fonts.baskervville,
    fontSize: { xs: "30px", md: "61px" },
    lineHeight: { xs: "31px", md: "66px" },
    color: constants.colors.primary,
    textAlign: "center",
    m: { xs: "1rem", md: "3rem" },
  },
  text2: {
    fontFamily: constants.fonts.autography,
    fontSize: { xs: "35px", md: "100px" },
    lineHeight: { xs: "85px", md: "226px" },
    color: constants.colors.primary,
  },
  confirmBtn: {
    display: { xs: "block", md: "block" },
    color: constants.colors.primary,
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    letterSpacing: "2px",
    m: "1rem",
    mb: "3rem",
    border: "1px solid #2C4367",
  },
};
