import { Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { styles } from "./styles";

export const CountDown = ({ weddingDate }) => {
  const targetDate = new Date(weddingDate).getTime();
  const [currentTime, setCurrentTime] = useState(Date.now());

  const timeBetween = targetDate - currentTime;
  const seconds = Math.floor((timeBetween / 1000) % 60);
  const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));

  useEffect(() => {
    const count = setInterval(() => {
      setCurrentTime(Date.now());
    }, 1000);
    return () => {
      clearInterval(count);
    };
  }, [targetDate]);

  return (
    <Box sx={styles.container}>
      <Box sx={styles.daysBox}>
        <Typography sx={styles.separations}> {days} </Typography>
        <Typography sx={styles.text}> dias </Typography>
      </Box>
      <Box>
        <Typography sx={styles.numbers}> {hours} </Typography>
        <Typography sx={styles.text}> hrs </Typography>
      </Box>
      <Typography sx={styles.separations}> : </Typography>
      <Box>
        <Typography sx={styles.numbers}> {minutes} </Typography>
        <Typography sx={styles.text}> min </Typography>
      </Box>
      <Typography sx={styles.separations}> : </Typography>
      <Box>
        <Typography sx={styles.numbers}> {seconds} </Typography>
        <Typography sx={styles.text}> seg </Typography>
      </Box>
    </Box>
  );
};
