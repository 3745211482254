import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

export const FAQs = () => {
  const questions = [
    {
      question: "¿Habrá codigo de vestimenta? ¿Que debería ponerme?",
      answer:
        "R. La sugerencia para el código de vestimenta para nuestra boda es formal, aunque debido a el calor de Mayo tambien podria ser semiformal/cóctel. Una sugerencia para las damas es llevar calzado cómodo ya que el evento será en terraza/jardin.",
    },
    {
      question: "¿Se puede llevar niños?",
      answer:
        "R. Si bien amamos a sus pequeños, nuestra boda será un evento solo para adultos para que todos puedan relajarse y disfrutar de la noche. Agradecemos que hagas los arreglos con anticipación y dejes a los niños en casa para que puedan celebrar con nosotros.",
    },
    {
      question: "¿Habrá snacks o bebidas durante la recepción?",
      answer:
        "R. Durante la llegada a la terraza habrá mesa de snacks y se les dará un cóctel de bienvenida.",
    },
    {
      question:
        "Soy alérgico a ciertos ingredientes, ¿Puedo mencionarlo en la cena?",
      answer:
        "R. Por favor si tienes alguna alergia, házles saber a los meseros antes de recibir tus alimentos.",
    },
    {
      question: "¿Hay estacionamiento para invitados en el lugar de la fiesta?",
      answer:
        "R. Todos los invitados registrados, tendrán acceso al estacionamiento privado de la terraza, y contará con valet parking.",
    },
    {
      question: "¿Habrá transporte a las locaciones?",
      answer:
        "R. Los invitados registrados en el hotel del convenio, tendrán transporte a todas las locaciones de la boda incluyendo ida y regreso desde el hotel, más información en la sección Hotel.",
    },

    {
      question: "¿Puedo invitar mas personas de las que registré?",
      answer:
        "R. La entrada esta limitada a la cantidad de boletos mencionadas en la sección Datos de Invitado, pero si requieres un boleto más, por favor contacta a los novios. Por seguridad, se negará el acceso a las personas que no hayan sido registradas, incluidos sus acompañantes. Si ya estás registrado, nos vemos en la boda!",
    },
    {
      question: "¿Habrá mesa de regalos?",
      answer: `R. Tu presencia es más que suficiente para nosotros,  pero si gustas obsequiarnos algo, ve a la sección de Mesa de Regalos que está al final de la invitación.`,
    },
    {
      question: "¿Tengo más dudas, donde los contacto?",
      answer:
        "R. Por favor, si tienes más dudas contacta a Andrea y Felipe para responderlas.",
    },
  ];
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Preguntas frecuentes</Typography>
          </Box>
          {questions.map((q) => {
            const { question, answer } = q;
            return (
              <Box sx={{ m: "2rem" }} key={question}>
                <Box sx={{ m: "1rem" }}>
                  <Typography sx={styles.questions}>{question}</Typography>
                </Box>
                <Box>
                  <Typography sx={styles.questions}>{answer}</Typography>
                </Box>
              </Box>
            );
          })}
        </Box>
      </Grid>
    </Grid>
  );
};
