import img from "../../assets/flowersbcg.jpg";
import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: { xs: "auto", md: "auto" },
    backgroundImage: `url(${img})`,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    backgroundSize: "cover",
    color: constants.colors.primary,
  },
  titleContainer: { textAlign: "center" },
  title: {
    mt: "4rem",
    fontFamily: constants.fonts.autography,
    fontSize: { xs: "45px", md: "120px" },
    lineHeight: { xs: "90px", md: "206px" },
  },
  locationsContainer: {
    display: "flex",
    justifyContent: "space-evenly",
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "center", md: "normal" },
    textAlign: { xs: "center", md: "normal" },
  },
  locationBox: {
    width: { xs: "50%", md: "35%" },
    display: "flex",
    flexDirection: "column",
    // mb: { xs: "1rem", md: "1rem" },
  },
  subtitle: {
    color: constants.colors.primary,
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    mb: "1rem",
  },
  textBox: {
    height: { xs: "100px", md: "120px" },
    my: { xs: "0rem", md: "1rem" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
  },
  text: {
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    fontSize: { xs: "12px", md: "20px" },
    letterSpacing: "0.06em",
    lineHeight: { xs: "18px", md: "30px" },
  },
  btns: {
    width: { xs: "322px", md: "384px" },
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    letterSpacing: "2px",
    mt: "1rem",
    mb: "3rem",
    "&:hover": {
      backgroundColor: "#DFE9F2",
      color: constants.colors.primary,
    },
  },
};
