import { Box, Divider, Grid, Typography } from "@mui/material";
import {
  ChurchOutlined,
  TapasOutlined,
  PhotoCameraOutlined,
  DinnerDiningOutlined,
  CakeOutlined,
  SpeakerOutlined,
} from "@mui/icons-material";
import { styles } from "./styles";

export const Itinerary = () => {
  const itinerary = [
    {
      icon: <ChurchOutlined />,
      activity: "Misa",
      hour: "15:00 hrs",
    },
    { icon: <TapasOutlined />, activity: "Recepción", hour: "17:30 hrs" },
    {
      icon: <PhotoCameraOutlined />,
      activity: "Cóctel & fotos",
      hour: "18:00 hrs",
    },
    {
      icon: <PhotoCameraOutlined />,
      activity: "Fiesta",
      hour: "19:00 hrs",
    },
    { icon: <DinnerDiningOutlined />, activity: "Cena", hour: "20:30 hrs" },
    { icon: <CakeOutlined />, activity: "Pastel", hour: "22:00 hrs" },
    { icon: <SpeakerOutlined />, activity: "Banda de Rock", hour: "00:00 hrs" },
  ];

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item md={12}>
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Itinerario</Typography>{" "}
          </Box>
          <Box sx={styles.descriptionContainer}>
            <Typography sx={styles.description}>
              Cada momento es especial, te compartimos el itinerario para que no
              te pierdas nada.
            </Typography>
          </Box>
          <Box sx={styles.details}>
            <Box sx={{ textAlign: "right" }}>
              {itinerary.map((option) => {
                const { activity } = option;
                return (
                  <Box key={activity} sx={styles.leftList}>
                    <Typography sx={styles.list}>{activity}</Typography>
                  </Box>
                );
              })}
            </Box>
            <Divider sx={styles.divider} />
            <Box>
              {itinerary.map((option) => {
                const { hour } = option;
                return (
                  <Box key={hour}>
                    <Typography sx={styles.list}>{hour}</Typography>
                  </Box>
                );
              })}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
