import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: { xs: "auto", md: "100vh" },
    backgroundColor: constants.colors.primary,
    color: "white",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    fontFamily: constants.fonts.autography,
    fontSize: { xs: "45px", md: "120px" },
    lineHeight: { xs: "80px", md: "120px" },
    m: "4rem",
  },
  descriptionContainer: {
    textAlign: "center",
    mx: { xs: "1rem", md: "2rem" },
  },
  description: {
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    textAlign: "center",
  },
  details: {
    display: "flex",
    mx: "1rem",
    my: "5rem",
  },
  leftList: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  list: {
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    fontSize: { xs: "13px", md: "20px" },
    lineHeight: { xs: "30px", md: "30px" },
  },
  divider: {
    border: "1px solid white",
    mx: { xs: "2rem", md: "4rem" },
  },
};
