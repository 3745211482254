import * as React from "react";
import { Card, CardContent, Typography } from "@mui/material";

export const BeverageMetrics = ({ beverages }) => {
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        {Object.keys(beverages)
          .sort((b1, b2) => beverages[b2] - beverages[b1])
          .map((b) => (
            <Typography key={b} sx={{ fontSize: 14 }} gutterBottom>
              {`${b}: ${beverages[b]}`}
            </Typography>
          ))}
      </CardContent>
    </Card>
  );
};
