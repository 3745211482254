import { constants } from "../../utils/constants";
import img from "../../assets/flowersbcg.jpg";

export const styles = {
  container: {
    height: "639px",
    display: "flex",
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    mt: "4rem",
    fontFamily: constants.fonts.autography,
    color: constants.colors.primary,
    fontSize: { xs: "45px", md: "100px" },
    lineHeight: { xs: "90px", md: "150px" },
  },
  description: {
    mx: "2rem",
    fontFamily: constants.fonts.poppins,
    color: constants.colors.primary,
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    textAlign: "center",
    letterSpacing: "0.06em",
  },
  text: {
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    color: constants.colors.primary,
    fontSize: { xs: "15px", md: "20px" },
    letterSpacing: "0.06em",
    lineHeight: { xs: "18px", md: "30px" },
    my: "2px",
    mx: "6px",
  },
  phoneContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    color: constants.colors.primary,
    mt: "2px",
  },
};
