import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { styles } from "./styles";

export const InfoGuest = ({ guestData }) => {
  const [open, setOpen] = useState(false);
  const { firstName, lastName, extraInvites } = guestData || {};
  const fullName = `${firstName} ${lastName}`;

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container} id="rsvp">
          <Box>
            <Typography sx={styles.title}>Datos de Invitado</Typography>{" "}
          </Box>
          <Box>
            <Typography sx={styles.guestName}>{fullName}</Typography>
          </Box>
          <Box>
            <Typography sx={styles.text2}>
              {Number(extraInvites || 0) + 1}{" "}
              {Number(extraInvites || 0) + 1 === 1 ? "Boleto" : "Boletos"}
            </Typography>
          </Box>
          <Box>
            <Button
              variant="outlined"
              sx={styles.confirmBtn}
              onClick={handleOpen}
            >
              Confirmar asistencia
            </Button>
            <ConfirmationDialog
              open={open}
              handleClose={handleClose}
              guestData={guestData}
            />
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
