import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  CircularProgress,
  Container,
  CssBaseline,
  Grid,
  Button,
} from "@mui/material";

import { PasswordPrompt } from "./PasswordPrompt";
import {
  createRSVP,
  fetchAllInvitees,
  removeRSVP,
  markAsSent,
  markAsRejected,
  updateRSVP,
} from "../../services/apis";
import { RsvpList } from "./RsvpList";
import { Metrics } from "./Metrics";
import { BeverageMetrics } from "./BeverageMetrics";
import { CreateRsvpModal } from "./CreateRsvpModal";
import { UpdateRsvpModal } from "./UpdateRsvpModal";
import * as WhatsApp from "../../utils/whatsapp";
import { API_PSW, WEB_URL, NODE_ENV } from "../../utils/constants";

export const Admin = () => {
  const [authorization, setAuthorization] = useState(
    NODE_ENV === "local" ? API_PSW : ""
  );
  const [showPasswordPrompt, setShowPasswordPrompt] = useState(true);
  const [rsvpList, setRsvpList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [metrics, setMetrics] = useState(null);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [errorSaving, setErrorSaving] = useState(false);
  const [errorSavingMessage, setErrorSavingMessage] = useState("");
  const [editingRow, setEditingRow] = useState({});

  const navigate = useNavigate();

  useEffect(() => {
    if (authorization) {
      setShowPasswordPrompt(false);
      fetchRsvpList();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authorization]);

  async function fetchRsvpList() {
    setIsLoading(true);
    const invitees = await fetchAllInvitees({ authorization });
    if (!invitees) {
      return navigate("/");
    }
    if (invitees?.length) {
      setRsvpList(invitees);
    }
    setIsLoading(false);
  }

  const handleMetrics = (metrics) => {
    setMetrics(metrics);
  };

  const openCreateRsvpModal = () => {
    setOpenCreateModal(true);
  };

  const handleCreateModalClose = async (rsvp) => {
    if (!rsvp.save) {
      setOpenCreateModal(false);
      return rsvp.clearValues();
    }
    try {
      await createRSVP({
        ...rsvp,
        authorization,
      });
      setOpenCreateModal(false);
      await fetchRsvpList();
      rsvp.clearValues();
    } catch (err) {
      setErrorSavingMessage(err.response?.data?.errors?.join(","));
      setErrorSaving(true);
    }
  };

  const sendWhatsAppMobile = async ({ firstName, phone, rsvpId }) => {
    WhatsApp.sendMobile({ firstName, phone, rsvpId });
    await markAsSent({ authorization, rsvpId });
  };

  const sendWhatsAppWeb = async ({ firstName, phone, rsvpId }) => {
    WhatsApp.sendWeb({ firstName, phone, rsvpId });
    await markAsSent({ authorization, rsvpId });
  };

  const deleteRsvp = async ({ rsvpId }) => {
    await removeRSVP({ rsvpId, authorization });
    await fetchRsvpList();
  };

  const rejectRsvp = async ({ rsvpId }) => {
    await markAsRejected({ rsvpId, authorization });
    await fetchRsvpList();
  };

  const refreshList = async () => {
    setIsLoading(true);
    await fetchRsvpList();
    setIsLoading(false);
  };

  const handleEdit = ({ row }) => {
    setEditingRow(row);
    setOpenEditModal(true);
  };

  const handleEditModalClose = async (rsvp) => {
    if (!rsvp.save) {
      setOpenEditModal(false);
      return rsvp.clearValues();
    }
    try {
      await updateRSVP({
        ...rsvp,
        authorization,
      });
      setOpenEditModal(false);
      await fetchRsvpList();
      rsvp.clearValues();
    } catch (err) {
      console.log(err);
      setErrorSavingMessage(err.response?.data?.errors?.join(","));
      setErrorSaving(true);
    }
  };

  const sendInviteSummary = async (rsvp) => {
    await WhatsApp.sendInviteSummary(rsvp);
  };

  return (
    <>
      <PasswordPrompt
        handleClose={(psw) => setAuthorization(psw)}
        open={showPasswordPrompt}
      />

      <CssBaseline />
      <Container maxWidth="xl">
        {isLoading && <CircularProgress />}
        {!isLoading && (
          <Grid container spacing={2}>
            <Grid item xs={4}>
              {metrics && <Metrics metrics={metrics} />}
            </Grid>
            <Grid item xs={4}>
              {metrics?.beverages && (
                <BeverageMetrics beverages={metrics.beverages} />
              )}
            </Grid>
            <Grid item xs={4}>
              {`Node Env: ${NODE_ENV}`}
              <br />
              {`Environment: ${WEB_URL}`}
              <br />
              <Button onClick={refreshList}>Recargar Lista</Button>
              <br />
              <br />
              <Button onClick={openCreateRsvpModal}>Invitar a uno mas</Button>
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              {rsvpList?.length && (
                <RsvpList
                  list={rsvpList}
                  handleMetrics={handleMetrics}
                  sendWhatsAppMobile={sendWhatsAppMobile}
                  sendWhatsAppWeb={sendWhatsAppWeb}
                  deleteRsvp={deleteRsvp}
                  rejectRsvp={rejectRsvp}
                  handleEdit={handleEdit}
                  sendInviteSummary={sendInviteSummary}
                />
              )}
            </Grid>
          </Grid>
        )}
      </Container>
      <CreateRsvpModal
        open={openCreateModal}
        handleClose={handleCreateModalClose}
        hasError={errorSaving}
        errorMessage={errorSavingMessage}
      />
      <UpdateRsvpModal
        rsvp={editingRow}
        open={openEditModal && editingRow != null}
        handleClose={handleEditModalClose}
      />
    </>
  );
};
