import { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
  FormControl,
  Input,
  Divider,
} from "@mui/material";
import { useEffect } from "react";
import { Delete as DeleteIcon } from "@mui/icons-material";
import { beverageOptions } from "../../utils/constants";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const UpdateRsvpModal = ({
  open,
  handleClose,
  hasError,
  errorMessage,
  rsvp = {},
}) => {
  const [firstName, setFirstName] = useState({
    error: false,
    value: rsvp.firstName,
  });
  const [lastName, setLastName] = useState({
    error: false,
    value: rsvp.lastName,
  });
  const [email, setEmail] = useState({ error: false, value: rsvp.email });
  const [phone, setPhone] = useState({ error: false, value: rsvp.phone });
  const [extraInvites, setExtraInvites] = useState(rsvp.extraInvites);
  const [hasChildren, setHasChildren] = useState(rsvp.hasChildren);
  const [childrenQty, setChildrenQty] = useState(rsvp.childrenQty);
  const [invitees, setInvitees] = useState(rsvp.invitees);
  const [beverages, setBeverages] = useState(rsvp.beverages ?? []);
  const [needHotel, setNeedHotel] = useState(rsvp.needHotel);
  const [accepted, setAccepted] = useState(rsvp.accepted);
  const [rejected, setRejected] = useState(rsvp.rejected);
  const [isActive, setIsActive] = useState(rsvp.isActive);

  useEffect(() => {
    setFirstName({
      error: false,
      value: rsvp.firstName,
    });
    setLastName({
      error: false,
      value: rsvp.lastName,
    });
    setEmail({ error: false, value: rsvp.email });
    setPhone({ error: false, value: rsvp.phone });
    setExtraInvites(rsvp.extraInvites);
    setHasChildren(rsvp.hasChildren);
    setChildrenQty(rsvp.childrenQty);
    setInvitees(rsvp.invitees);
    setBeverages(rsvp.beverages ?? []);
    setNeedHotel(rsvp.needHotel);
    setAccepted(rsvp.accepted);
    setRejected(rsvp.rejected);
    setIsActive(rsvp.isActive);
  }, [rsvp]);

  const clearValues = () => {
    setFirstName({ error: false, value: "" });
    setLastName({ error: false, value: "" });
    setEmail({ error: false, value: "" });
    setPhone({ error: false, value: "" });
    setExtraInvites("1");
    setHasChildren(false);
    setChildrenQty(0);
    setInvitees([]);
    setBeverages([]);
    setNeedHotel(false);
    setAccepted(false);
    setRejected(false);
    setIsActive(false);
  };

  const handleFirstNameChange = (e) => {
    const newValue = {
      ...firstName,
      value: e.target.value,
    };
    setFirstName(newValue);
  };

  const handleLastNameChange = (e) => {
    const newValue = {
      ...lastName,
      value: e.target.value,
    };
    setLastName(newValue);
  };

  const handleEmailChange = (e) => {
    const newValue = {
      ...email,
      value: e.target.value,
    };
    setEmail(newValue);
  };

  const handlePhoneChange = (e) => {
    const newValue = {
      ...phone,
      value: e.target.value,
    };
    setPhone(newValue);
  };

  const handleSave = () => {
    // validate
    let error = false;

    if (firstName.value.length <= 2) {
      setFirstName({ ...firstName, error: true });
      error = true;
    }

    if (lastName.value.length <= 2) {
      setLastName({ ...lastName, error: true });
      error = true;
    }

    if (!email.value.match(emailRegex)) {
      setEmail({ ...email, error: true });
      error = true;
    }

    if (phone.value.length < 10) {
      setPhone({ ...phone, error: true });
      error = true;
    }

    if (error) return;
    // save
    handleClose({
      save: true,
      clearValues,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      extraInvites,
      hasChildren,
      childrenQty,
      invitees,
      beverages,
      needHotel,
      accepted,
      rejected,
      isActive,
      rsvpId: rsvp.rsvpId,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose({ clearValues })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="form"
            autoComplete="off"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <TextField
              required
              id="firstName"
              label="Nombre"
              variant="filled"
              value={firstName.value}
              error={firstName.error}
              onChange={handleFirstNameChange}
            />
            <TextField
              required
              id="lastName"
              label="Apellidos"
              variant="filled"
              value={lastName.value}
              error={lastName.error}
              onChange={handleLastNameChange}
            />
            <TextField
              required
              id="email"
              label="Correo Electronico"
              variant="filled"
              value={email.value}
              error={email.error}
              onChange={handleEmailChange}
            />
            <TextField
              required
              type="number"
              id="phone"
              label="Telefono (Whatsapp)"
              variant="filled"
              value={phone.value}
              error={phone.error}
              onChange={handlePhoneChange}
            />
            <Divider />
            <TextField
              required
              label="Invitaciones Extra"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              value={extraInvites}
              onChange={(e) => setExtraInvites(e.target.value)}
            />
            <br />
            Invitados:
            <br />
            {invitees?.map((invitee, i) => (
              <FormControl>
                <Input
                  key={i}
                  required
                  label="Invitacion Extra"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="standard"
                  value={invitee}
                  onChange={(e) => {
                    const newInvitees = [...invitees];
                    newInvitees[i] = e.target.value;
                    setInvitees(newInvitees);
                  }}
                  endAdornment={
                    <DeleteIcon
                      onClick={() => {
                        const newInvitees = [...invitees];
                        newInvitees.splice(i, 1);
                        setInvitees(newInvitees);
                      }}
                    />
                  }
                />
              </FormControl>
            ))}
            <Button
              onClick={() => {
                const newInvitees = [...(invitees ?? []), ""];
                setInvitees(newInvitees);
              }}
            >
              Agregar Invitado
            </Button>
            <br />
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasChildren}
                  onChange={(e) => setHasChildren(e.target.checked)}
                />
              }
              label="Tiene Hijos"
            />
            <br />
            {hasChildren && (
              <>
                Cuantos niños va a traer?
                <Input
                  type="number"
                  value={childrenQty}
                  onChange={(e) => setChildrenQty(e.target.value)}
                />
                <br />
              </>
            )}
            <Divider />
            Bebidas
            <br />
            {Object.keys(beverageOptions).map((b) => {
              const beverage = b.toUpperCase();

              return (
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={beverages.includes(beverage)}
                      onChange={(e) => {
                        if (e.target.checked) {
                          beverages.push(beverage);
                        } else {
                          const indx = beverages.findIndex(
                            (e) => e === beverage
                          );
                          beverages.splice(indx, 1);
                        }
                        setBeverages([...beverages]);
                      }}
                    />
                  }
                  label={beverage}
                />
              );
            })}
            <Divider />
            <FormControlLabel
              control={
                <Checkbox
                  checked={needHotel}
                  onChange={(e) => setNeedHotel(e.target.checked)}
                />
              }
              label="Necesita Hotel"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                />
              }
              label="Invitacion Activa"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={accepted}
                  onChange={(e) => setAccepted(e.target.checked)}
                />
              }
              label="Invitacion Aceptada"
            />
            <br />
            <FormControlLabel
              control={
                <Checkbox
                  checked={rejected}
                  onChange={(e) => setRejected(e.target.checked)}
                />
              }
              label="Invitacion Rechazada"
            />
            <Button onClick={handleSave}>Guardar</Button>
          </Box>
          {hasError && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </Modal>
    </div>
  );
};
