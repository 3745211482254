import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: "auto",
    backgroundColor: constants.colors.primary,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    mt: "2rem",
    fontFamily: constants.fonts.autography,
    color: constants.colors.white,
    fontSize: { xs: "45px", md: "100px" },
    lineHeight: { xs: "90px", md: "150px" },
  },
  accordion: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-evenly",
    backgroundColor: constants.colors.primary,
  },
  questions: {
    fontFamily: constants.fonts.poppins,
    color: constants.colors.white,
    fontSize: { xs: "12px", md: "20px" },
    letterSpacing: "0.06em",
    lineHeight: { xs: "18px", md: "30px" },
    fontWeight: 600,
    textAlign: "center",
    m: "1rem",
  },
};
