import { API_URL } from "../utils/constants";
import axios from "axios";

/**
 * PUBLIC
 */
export const checkHealth = async () => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/health`,
    });
    return response;
  } catch (err) {
    console.log("err", err);
  }
  return null;
};

export const checkInvite = async (rsvpId) => {
  try {
    const response = await axios({
      method: "GET",
      url: `${API_URL}/api/v1/public/rsvp/${rsvpId}`,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const acceptInvite = async (rsvpId, data) => {
  try {
    const response = await axios({
      method: "POST",
      url: `${API_URL}/api/v1/public/rsvp/${rsvpId}`,
      data,
    });
    return response.data;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

/**
 * ADMIN
 */
export const fetchAllInvitees = async ({ authorization }) => {
  try {
    const response = await axios(
      {
        method: "get",
        url: `${API_URL}/api/v1/admin/rsvp`,
        headers: {
          "Access-Control-Allow-Origin": "*",
          Authorization: authorization,
        },
      },
      {
        timeout: 0,
      }
    );
    const {
      data: {
        rsvp: { Items },
      },
    } = response;
    return Items;
  } catch (err) {
    console.log("err", err);
    return null;
  }
};

export const createRSVP = async ({ authorization, ...rsvp }) => {
  const response = await axios(
    {
      method: "post",
      data: rsvp,
      url: `${API_URL}/api/v1/admin/rsvp`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: authorization,
      },
    },
    {
      timeout: 0,
    }
  );
  return response;
};

export const updateRSVP = async ({ authorization, rsvpId, ...rsvp }) => {
  console.log({ authorization, rsvpId, rsvp });
  const response = await axios(
    {
      method: "put",
      data: rsvp,
      url: `${API_URL}/api/v1/admin/rsvp/${rsvpId}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: authorization,
      },
    },
    {
      timeout: 0,
    }
  );
  return response;
};

export const removeRSVP = async ({ authorization, rsvpId }) => {
  const response = await axios(
    {
      method: "delete",
      url: `${API_URL}/api/v1/admin/rsvp/${rsvpId}`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: authorization,
      },
    },
    {
      timeout: 0,
    }
  );
  return response;
};

export const markAsSent = async ({ authorization, rsvpId }) => {
  const response = await axios(
    {
      method: "post",
      url: `${API_URL}/api/v1/admin/rsvp/${rsvpId}/sent`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: authorization,
      },
    },
    {
      timeout: 0,
    }
  );
  return response;
};

export const markAsRejected = async ({ authorization, rsvpId }) => {
  const response = await axios(
    {
      method: "post",
      url: `${API_URL}/api/v1/admin/rsvp/${rsvpId}/reject`,
      headers: {
        "Access-Control-Allow-Origin": "*",
        Authorization: authorization,
      },
    },
    {
      timeout: 0,
    }
  );
  return response;
};
