import { Box, Button, Grid, Typography } from "@mui/material";
import { useState } from "react";
import { ConfirmationDialog } from "../../components/ConfirmationDialog/ConfirmationDialog";
import { styles } from "./styles";

export const ConfirmationPage = ({ guestData }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={7}>
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Confirmar asistencia</Typography>
          </Box>
          <Box>
            <Typography sx={styles.description}>
              Confirma tu asistencia antes del 01 de Mayo
            </Typography>
          </Box>
          {guestData != null && (
            <Box>
              <Button
                variant="outlined"
                sx={styles.confirmBtn}
                onClick={handleOpen}
              >
                Confirmar asistencia
              </Button>
              <ConfirmationDialog
                open={open}
                handleClose={handleClose}
                guestData={guestData}
              />
            </Box>
          )}
        </Box>
      </Grid>
      <Grid item xs={12} md={5}>
        <Box sx={styles.photo}></Box>
      </Grid>
    </Grid>
  );
};
