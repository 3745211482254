const {
  REACT_APP_NODE_ENV,
  REACT_APP_API_URL,
  REACT_APP_WEB_URL,
  REACT_APP_API_PSW,
} = process.env;

export const API_URL = REACT_APP_API_URL;
export const API_PSW = REACT_APP_API_PSW ?? "";
export const WEB_URL = REACT_APP_WEB_URL;
export const NODE_ENV = REACT_APP_NODE_ENV;

export const constants = {
  colors: {
    white: "white",
    primary: "#2C4367",
  },
  fonts: {
    poppins: "poppins",
    autography: "Autography",
    baskervville: "baskervville",
  },
};

export const beverageOptions = {
  wine: "WINE",
  tequila: "TEQUILA",
  whiskey: "WHISKEY",
  vodka: "VODKA",
  beer: "BEER",
  craft_beer: "CRAFT_BEER",
  not_drink: "NOT_DRINK",
};

export const beverageFriendlyName = {
  WINE: "Vino 🍷",
  TEQUILA: "Tequila 🍹",
  WHISKEY: "Whiskey 🥃",
  VODKA: "Vodka 🍸",
  BEER: "Cerveza 🍻",
  CRAFT_BEER: "Cerveza Artesanal🍺",
  NOT_DRINK: "No alcohol 🧃",
};
