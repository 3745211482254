import { WEB_URL, beverageFriendlyName } from "./constants";

export const buildText = ({ firstName, url }) => {
  return `¡Hola ${firstName}!

Si te llega este mensaje, es por que estas invitado a nuestra boda! ⛪👰🏻‍♀️🤵🏻
puedes confirmar tu asistencia en el siguiente link:

${url}

Es importante que confirmes tu asistencia antes de el 1 de Mayo para asegurar tu lugar!!
Esperamos ansiosamente contar con tu presencia, por favor registra a tus invitados para asegurar el lugar de ellos también. Nos vemos en la boda!!

Atte: Felipe&Andy`;
};

export const getUrl = ({ rsvpId }) => {
  return `${WEB_URL}#/?rsvpId=${rsvpId}`;
};

const sendWhatsappMessage = ({ phone, message }) => {
  const link = `https://api.whatsapp.com/send?phone=${phone}&text=${encodeURIComponent(
    message
  )}`;
  window.open(link, "_blank");
};

export const sendMobile = ({ firstName, phone, rsvpId }) => {
  const url = getUrl({ rsvpId });
  const message = buildText({ firstName, url });

  const linkToWhatsapp =
    "whatsapp://send?phone=" +
    encodeURIComponent(phone) +
    "&text=" +
    encodeURIComponent(message);
  window.open(linkToWhatsapp);
};

export const sendWeb = ({ firstName, phone, rsvpId }) => {
  const url = getUrl({ rsvpId });
  const message = buildText({ firstName, url });

  sendWhatsappMessage({ phone, message });
};

const buildInvitees = ({ firstName, lastName, invitees }) => {
  const list = [`${firstName} ${lastName}`, ...(invitees ?? [])];
  console.log(list);
  return list.join(`
`);
};

const buildBeverages = ({ beverages }) => {
  return beverages.map((b) => beverageFriendlyName[b]).join(`
`);
};

const buildNeedHotelMessage = ({ needHotel }) => {
  if (!needHotel) return "Indicaste que no requerias hotel.";

  return `Indicaste que requerias hotel 🛏️
En la pagina de la reservación esta el link para poder contactar al hotel en caso de no tener cuarto aun, recuerda usar el Código de reservación para que tu estancia sea mas barata.`;
};

export const sendInviteSummary = (invite) => {
  console.log(invite);
  const { firstName, phone } = invite;

  const message = `¡Hola ${firstName} 🖖🏼!

Te mando un resumen de tu reservación para nuestra boda:

Nos acompañan 🕺🏻💃🏼: 
${buildInvitees(invite)}

Bebidas que tomarás:
${buildBeverages(invite)}

${buildNeedHotelMessage(invite)}

Es importante que sepas que las personas que no están en la lista de invitados ⛔no podrán acceder⛔ a la fiesta por políticas del local del evento
(esta política incluye niños no registrados)

No es necesario contestar este mensaje -A MENOS- de que tengas que hacer una modificación📝 a la invitación o tengas que cancelar tu asistencia😿.

Atte: Felipe&Andy`;

  sendWhatsappMessage({ phone, message });
};
