import { useState } from "react";
import {
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

export const PasswordPrompt = ({ handleClose, open }) => {
  const [password, setPassword] = useState("");

  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Admin site</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Estas accediendo a zona prohibida, si conoces la contraseña,
            escribela ahora
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="password"
            label="Contraseña"
            type="email"
            fullWidth
            variant="standard"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose(false)}>Cancel</Button>
          <Button onClick={() => handleClose(password)}>Continuar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
