import { Box, Button, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

export const WeddingGifts = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Mesa de Regalos</Typography>
          </Box>
          <Box sx={{ mx: "5rem" }}>
            <Typography sx={styles.description}>
              Tu compañía es nuestro mejor regalo, pero si deseas obsequiarnos
              algo te dejamos algunas opciones:
            </Typography>
          </Box>
          <Box sx={styles.btnsGroup}>
            <Box>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                href="https://mesaderegalos.liverpool.com.mx/milistaderegalos/50975810"
              >
                <Button variant="outlined" sx={styles.btn}>
                  Liverpool
                </Button>
              </a>
            </Box>
            <Box>
              <a
                target="_blank"
                rel="noreferrer"
                style={{ textDecoration: "none" }}
                href="https://www.amazon.com.mx/wedding/felipe-de-jesus-nevarez-perez-andrea-elizabeth-jimenez-hernandez-guadalajara-may-2023/registry/27F1XMLZJBDB9"
              >
                <Button variant="outlined" sx={styles.btn}>
                  Amazon
                </Button>
              </a>
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
