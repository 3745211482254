import img from "../../assets/flowersbcg.jpg";
import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: "639px",
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  title: {
    mt: "4rem",
    fontFamily: constants.fonts.autography,
    color: constants.colors.primary,
    fontSize: { xs: "45px", md: "100px" },
    lineHeight: { xs: "90px", md: "150px" },
  },
  description: {
    fontFamily: constants.fonts.poppins,
    color: constants.colors.primary,
    width: { xs: "289px", md: "1000px" },
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    textAlign: "center",
  },
  btnsGroup: {
    display: "flex",
    flexDirection: { xs: "column", md: "row" },
    justifyContent: "space-evenly",
    mb: { xs: "4rem", md: "0rem" },
  },
  btn: {
    width: { xs: "322px", md: "384px" },
    m: { xs: "1rem", md: "4rem" },
  },
};
