import img from "../../assets/flowersbcg.jpg";
import { constants } from "../../utils/constants";

export const styles = {
  container: {
    height: { xs: "274px", md: "100vh" },
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
  },
  description: {
    fontFamily: constants.fonts.poppins,
    color: constants.colors.primary,
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    mx: { xs: "2rem", md: "2rem" },
    textAlign: "center",
  },
};
