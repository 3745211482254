import { useState } from "react";
import {
  Box,
  Button,
  Modal,
  TextField,
  Checkbox,
  FormControlLabel,
  Alert,
} from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const emailRegex =
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

export const CreateRsvpModal = ({
  open,
  handleClose,
  hasError,
  errorMessage,
}) => {
  const [firstName, setFirstName] = useState({ error: false, value: "" });
  const [lastName, setLastName] = useState({ error: false, value: "" });
  const [email, setEmail] = useState({ error: false, value: "" });
  const [phone, setPhone] = useState({ error: false, value: "" });
  const [extraInvites, setExtraInvites] = useState("1");
  const [hasChildren, setHasChildren] = useState(false);

  const clearValues = () => {
    setFirstName({ error: false, value: "" });
    setLastName({ error: false, value: "" });
    setEmail({ error: false, value: "" });
    setPhone({ error: false, value: "" });
    setExtraInvites("1");
    setHasChildren(false);
  };

  const handleFirstNameChange = (e) => {
    const newValue = {
      ...firstName,
      value: e.target.value,
    };
    setFirstName(newValue);
  };

  const handleLastNameChange = (e) => {
    const newValue = {
      ...lastName,
      value: e.target.value,
    };
    setLastName(newValue);
  };

  const handleEmailChange = (e) => {
    const newValue = {
      ...email,
      value: e.target.value,
    };
    setEmail(newValue);
  };

  const handlePhoneChange = (e) => {
    const newValue = {
      ...phone,
      value: e.target.value,
    };
    setPhone(newValue);
  };

  const handleSave = () => {
    // validate
    let error = false;

    if (firstName.value.length <= 2) {
      setFirstName({ ...firstName, error: true });
      error = true;
    }

    if (lastName.value.length <= 2) {
      setLastName({ ...lastName, error: true });
      error = true;
    }

    if (!email.value.match(emailRegex)) {
      setEmail({ ...email, error: true });
      error = true;
    }

    if (phone.value.length < 10) {
      setPhone({ ...phone, error: true });
      error = true;
    }

    if (error) return;
    // save
    handleClose({
      save: true,
      clearValues,
      firstName: firstName.value,
      lastName: lastName.value,
      email: email.value,
      phone: phone.value,
      extraInvites,
      hasChildren,
    });
  };

  return (
    <div>
      <Modal
        open={open}
        onClose={() => handleClose({ clearValues })}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            component="form"
            autoComplete="off"
            sx={{
              "& .MuiTextField-root": { m: 1, width: "25ch" },
            }}
          >
            <TextField
              required
              id="firstName"
              label="Nombre"
              variant="filled"
              value={firstName.value}
              error={firstName.error}
              onChange={handleFirstNameChange}
            />
            <TextField
              required
              id="lastName"
              label="Apellidos"
              variant="filled"
              value={lastName.value}
              error={lastName.error}
              onChange={handleLastNameChange}
            />
            <TextField
              required
              id="email"
              label="Correo Electronico"
              variant="filled"
              value={email.value}
              error={email.error}
              onChange={handleEmailChange}
            />
            <TextField
              required
              type="number"
              id="phone"
              label="Telefono (Whatsapp)"
              variant="filled"
              value={phone.value}
              error={phone.error}
              onChange={handlePhoneChange}
            />
            <TextField
              required
              label="Invitaciones Extra"
              type="number"
              InputLabelProps={{
                shrink: true,
              }}
              variant="standard"
              value={extraInvites}
              onChange={(e) => setExtraInvites(e.target.value)}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasChildren}
                  onChange={(e) => setHasChildren(e.target.checked)}
                />
              }
              label="Tiene Hijos"
            />

            <Button onClick={handleSave}>Guardar</Button>
          </Box>
          {hasError && <Alert severity="error">{errorMessage}</Alert>}
        </Box>
      </Modal>
    </div>
  );
};
