import { Add, Cancel } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContentText,
  DialogActions,
  DialogContent,
  TextField,
  Box,
  Typography,
  IconButton,
  FormGroup,
  FormControlLabel,
  Checkbox,
  RadioGroup,
  Radio,
  CircularProgress,
  Alert,
} from "@mui/material";
import { useState, useEffect } from "react";
import { acceptInvite } from "../../services/apis";
import { styles } from "./styles";

export const beverageOptions = {
  wine: "WINE",
  tequila: "TEQUILA",
  whiskey: "WHISKEY",
  vodka: "VODKA",
  beer: "BEER",
  craft_beer: "CRAFT_BEER",
  not_drink: "NOT_DRINK",
};

export const ConfirmationDialog = ({ open, handleClose, guestData }) => {
  const [companionsList, setCompanionsList] = useState([]);
  const [mode, setMode] = useState(1);
  const [beveragesList, setBeveragesList] = useState([]);
  const [hotelConfirmation, setHotelConfirmation] = useState(true);
  const [confirmedInvite, setConfirmedInvite] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { rsvpId, firstName, lastName, extraInvites, accepted } =
    guestData || {};
  const fullName = `${firstName} ${lastName}`;

  useEffect(() => {
    if (accepted) {
      setMode(4);
    }
  }, [accepted]);

  const handleAddCompanion = () => {
    setCompanionsList([
      ...companionsList,
      { id: companionsList.length, fullName: "" },
    ]);
  };

  const handleRemoveCompanion = (index) => {
    const list = [...companionsList];
    list.splice(index, 1);
    setCompanionsList(list);
  };

  const handleCompanionChange = ({ fullName, id }) => {
    const newList = [...companionsList];
    newList[id].fullName = fullName;
    setCompanionsList(newList);
  };

  const handleBeveragesChange = (e) => {
    setBeveragesList({
      ...beveragesList,
      [e.target.name]: e.target.checked,
    });
  };

  const handleHotelConfirmationChange = (e) => {
    setHotelConfirmation({
      [e.target.name]: e.target.value === "Yes" ? true : false,
    });
  };

  const handleNextStep = () => {
    setMode(mode + 1);
  };

  const handleSave = async () => {
    setIsSaving(true);
    setHasError(false);
    const newList = companionsList.map((plus) => {
      return plus.fullName;
    });
    const beverages = Object.keys(beveragesList).filter(
      (b) => beveragesList[b] === true
    );
    const data = {
      invitees: newList,
      beverages,
      needHotel: hotelConfirmation?.Yes === true,
    };
    const response = await acceptInvite(rsvpId, data);
    setIsSaving(false);
    if (!response) {
      setHasError(true);
      return;
    }
    setConfirmedInvite(true);
    handleClose();
    setMode(1);
  };

  return (
    <Dialog open={open} onClose={handleClose} sx={styles.container}>
      {hasError && (
        <Alert severity="error">
          Ocurrió un problema, revisa tu información, si el problema persiste,
          contacta a los organizadores del evento.
        </Alert>
      )}
      {confirmedInvite ? (
        <>
          <DialogTitle m={1} sx={{ textAlign: "center" }}>
            Nos vemos el día de la boda!
          </DialogTitle>
          <DialogContent m={1}>
            <DialogContentText>
              Tu asistencia ya esta confirmada!
            </DialogContentText>
          </DialogContent>
        </>
      ) : (
        <>
          {mode === 1 && (
            <>
              <DialogTitle sx={{ textAlign: "center" }}>
                Ingresa tus acompañantes
              </DialogTitle>
              <DialogContent m={1}>
                <DialogContentText>
                  Nos emociona que nos puedas acompañar! Registra el nombre
                  completo de tus acompañantes.
                  <br />
                  Este evento está reservado solo para adultos.
                  <br />
                  NO NIÑOS
                </DialogContentText>
                <Box
                  sx={{
                    m: "1rem",
                  }}
                >
                  <Box sx={styles.form}>
                    <TextField
                      variant="outlined"
                      label={fullName}
                      fullWidth
                      disabled
                      name="main"
                      margin="dense"
                      sx={styles.inputs}
                    />
                    {companionsList.map(({ fullName, id }, index) => {
                      return (
                        <Box key={index} sx={styles.plusForm}>
                          <Box width={"100%"} sx={styles.inputBox}>
                            <TextField
                              name={`companion.${id}`}
                              variant="outlined"
                              label={`Acompañante ${id + 1}`}
                              fullWidth
                              required
                              margin="dense"
                              sx={styles.inputs}
                              placeholder={"Nombre completo..."}
                              value={fullName}
                              onChange={(e) => {
                                handleCompanionChange({
                                  fullName: e.target.value,
                                  id,
                                });
                              }}
                              InputProps={{
                                inputProps: { style: { color: "#fff" } },
                              }}
                            />
                          </Box>
                          <Box>
                            <IconButton
                              sx={styles.iconBtn}
                              onClick={() => {
                                handleRemoveCompanion(index);
                              }}
                            >
                              <Cancel />
                            </IconButton>
                          </Box>
                        </Box>
                      );
                    })}
                  </Box>
                  {extraInvites > companionsList.length && (
                    <Box sx={styles.addBtnBox}>
                      <IconButton
                        sx={styles.iconBtn}
                        onClick={handleAddCompanion}
                      >
                        <Add />
                      </IconButton>
                      <Typography m={1} sx={styles.addText}>
                        Agregar invitado
                      </Typography>
                    </Box>
                  )}
                </Box>
              </DialogContent>
            </>
          )}
          {mode === 2 && (
            <>
              <DialogTitle sx={{ textAlign: "center" }}>
                Dinos qué vas a tomar
              </DialogTitle>
              <DialogContent m={1}>
                <DialogContentText>
                  Sabemos que ese día no habra límites, pero queremos saber que
                  te gustaria tomar?
                </DialogContentText>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                  }}
                >
                  <FormGroup sx={{ m: 3 }}>
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.wine}
                          checked={beveragesList[beverageOptions.wine] || false}
                        />
                      }
                      label="Vino"
                    />
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.tequila}
                          checked={
                            beveragesList[beverageOptions.tequila] || false
                          }
                        />
                      }
                      label="Tequila"
                    />
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.whiskey}
                          checked={
                            beveragesList[beverageOptions.whiskey] || false
                          }
                        />
                      }
                      label="Whiskey"
                    />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.vodka}
                          checked={
                            beveragesList[beverageOptions.vodka] || false
                          }
                        />
                      }
                      label="Vodka"
                    />
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.beer}
                          checked={beveragesList[beverageOptions.beer] || false}
                        />
                      }
                      label="Cerveza"
                    />
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.craft_beer}
                          checked={
                            beveragesList[beverageOptions.craft_beer] || false
                          }
                        />
                      }
                      label="Cerveza artesanal"
                    />
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Checkbox
                          sx={styles.checkbox}
                          onChange={handleBeveragesChange}
                          name={beverageOptions.not_drink}
                          checked={
                            beveragesList[beverageOptions.not_drink] || false
                          }
                        />
                      }
                      label="No tomo"
                    />
                  </FormGroup>
                </Box>
              </DialogContent>
            </>
          )}
          {mode === 3 && (
            <>
              <DialogTitle sx={{ textAlign: "center" }}>Hospedaje</DialogTitle>
              <DialogContent m={1}>
                <DialogContentText>
                  Necesitas Hospedaje? Hicimos un convenio con un Hotel que
                  contará con transporte a todas las locaciones.
                  <br /> Ve a la sección Hotel para más información y reservar
                  tu lugar.
                  <br /> Si seleccionas que si, te mandaremos más información
                  sobre este convenio.
                </DialogContentText>
                <Box sx={styles.radioGroup}>
                  <RadioGroup
                    aria-labelledby="hotel-confirmation"
                    defaultValue="No"
                    name="hotel-confirmation"
                  >
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Radio
                          sx={styles.checkbox}
                          onChange={handleHotelConfirmationChange}
                          name="Yes"
                        />
                      }
                      label="Si"
                      value="Yes"
                    ></FormControlLabel>
                    <FormControlLabel
                      sx={styles.checkboxLabel}
                      control={
                        <Radio
                          sx={styles.checkbox}
                          onChange={handleHotelConfirmationChange}
                          name="No"
                        />
                      }
                      label="No"
                      value="No"
                    ></FormControlLabel>
                  </RadioGroup>
                </Box>
              </DialogContent>
            </>
          )}
          {mode === 4 && (
            <>
              <DialogTitle m={1} sx={{ textAlign: "center" }}>
                Nos vemos el día de la boda!
              </DialogTitle>
              <DialogContent m={1}>
                <DialogContentText>
                  Gracias por confirmar tu asistencia!
                </DialogContentText>
              </DialogContent>
            </>
          )}
          <DialogActions>
            {!accepted && mode <= 3 && (
              <Button
                variant="outlined"
                sx={styles.btn}
                onClick={() => {
                  setHasError(false);
                  if (mode === 1) {
                    handleClose();
                  } else {
                    setMode(mode - 1);
                  }
                }}
                disabled={isSaving}
              >
                Regresar
              </Button>
            )}

            {mode === 3 && !accepted && (
              <Button
                variant="outlined"
                sx={styles.btn}
                onClick={() => handleSave()}
                disabled={isSaving}
              >
                {isSaving ? <CircularProgress /> : "Guardar"}
              </Button>
            )}
            {mode < 3 && (
              <Button
                variant="outlined"
                sx={styles.btn}
                onClick={handleNextStep}
              >
                Siguiente
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );
};
