import * as React from "react";
import { Card, CardContent, Typography } from "@mui/material";

export const Metrics = ({ metrics }) => {
  const totalComing = metrics.accepted + metrics.extra;
  const pending = metrics.invites - metrics.rejected;

  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Total de invitaciones enviadas: ${metrics.invites}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Invitaciones Pendientes: ${pending}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Total de personas invitadas: ${metrics.invited}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Total de niños: ${metrics.children}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Aceptadas: ${metrics.accepted}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Acompañantes: ${metrics.extra}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Rechazadas: ${metrics.rejected}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Canceladas: ${metrics.inactive}`}
        </Typography>
        <Typography sx={{ fontSize: 14 }} gutterBottom>
          {`Totales que vienen: ${totalComing}`}
        </Typography>
      </CardContent>
    </Card>
  );
};
