// import img from "../../assets/a&f2.png";
import img from "../../assets/a&f2.jpg";

import { constants } from "../../utils/constants";

export const styles = {
  presentation: {
    width: "100%",
    m: "0 auto",
    p: "0 24px",
    height: { xs: "100vh", md: "100vh" },
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    backgroundColor: { xs: "none", md: constants.colors.primary },
    backgroundImage: { xs: `url(${img})`, md: "none" },
    backgroundSize: { xs: "cover", md: "none" },
    backgroundPosition: "center",
    padding: 0,
  },
  bottomText: {
    color: "white",
    textAlign: "center",
  },
  names: {
    m: "1rem",
    fontFamily: constants.fonts.autography,
    fontSize: { xs: "45px", md: "95px" },
  },
  date: {
    fontFamily: constants.fonts.baskervville,
    fontSize: { xs: "24px", md: "51px" },
    lineHeight: { xs: "31px", md: "66px" },
  },
  confirmBtn: {
    display: "block",
    color: "white",
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    letterSpacing: "2px",
    border: "1px solid white",
  },
  right: {
    width: "50%",
    backgroundColor: "#111411",
  },
  picture: {
    display: { xs: "none", md: "block" },
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "100vh",
  },
};
