import { WhatsApp } from "@mui/icons-material";
import { Box, Grid, Typography } from "@mui/material";
import { styles } from "./styles";

export const Hotel = () => {
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={12}>
        <Box sx={styles.container}>
          <Box>
            <Typography sx={styles.title}>Convenio Hotel</Typography>
          </Box>
          <Box>
            <Typography sx={styles.description}>
              Tenemos convenio con el Hotel Micro que se encuentra en la Plaza
              Gourmetería, el cual también contará con transporte a todas las
              locaciones de la boda.
            </Typography>
          </Box>
          <Typography sx={styles.text}>Reservaciones WhatsApp:</Typography>
          <Box sx={styles.phoneContainer}>
            <Typography sx={styles.text}>Micro Hotel</Typography>
          </Box>
          <Box sx={styles.phoneContainer}>
            <a href="https://api.whatsapp.com/send?phone=3314890867">
              <WhatsApp sx={styles.icon} />
            </a>
            <Typography sx={styles.text}>33 14 89 08 67</Typography>
          </Box>
          <Box>
            <Typography sx={styles.text}>Código: 051823BOD</Typography>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
