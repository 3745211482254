import { constants } from "../../utils/constants";
import img from "../../assets/a&f3.png";

export const styles = {
  container: {
    height: "537px",
    backgroundColor: constants.colors.primary,
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    justifyContent: "space-evenly",
  },
  photo: {
    backgroundImage: `url(${img})`,
    backgroundSize: "cover",
    height: { xs: "264px", md: "537px" },
    backgroundPosition: "center",
    width: "100%",
  },
  title: {
    fontFamily: constants.fonts.autography,
    color: constants.colors.white,
    fontSize: { xs: "45px", md: "100px" },
    lineHeight: { xs: "80px", md: "150px" },
  },
  description: {
    fontFamily: constants.fonts.poppins,
    color: constants.colors.white,
    fontWeight: 600,
    fontSize: { xs: "18px", md: "30px" },
    lineHeight: "30px",
    mx: { xs: "3rem", md: "1rem" },
  },
  confirmBtn: {
    color: "white",
    fontFamily: constants.fonts.poppins,
    fontWeight: 600,
    letterSpacing: "2px",
    mt: "2rem",
    border: "1px solid white",
  },
};
